import { Avatar, List, Button, PageHeader, Typography } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Container } from 'reactstrap'
import { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';
import { getStoreById } from '../services/store.service';
import { displayCADMoney } from '../helpers/util';
import styled from 'styled-components';
import DetailOrderSection from '../components/DetailOrderContainer';
const defaultImg = 'https://prod-grubx.s3.amazonaws.com/dish-default.jpeg';
const { Title } = Typography;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-bottom: 140px;
`;

const ProductsContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 50px;
		overflow-y: scroll;
		flex: 3;
`;

function Cart({ history }) {
	const { cart, setStore, updateCartItem } = useContext(AppContext);

	useEffect(() => {
		const fetchStore = async (storeId) => {
			const _store = await getStoreById(storeId);
			setStore(_store);
		}
		if (cart.storeId) fetchStore(cart.storeId);
	}, [cart, setStore])

	const _updateCartItem = async (lineItemId, quantity) => {
		await updateCartItem(lineItemId, quantity);
	}

	const goToCheckout = () => {
		if (cart.cartItems.length > 0) {
			history.push('/checkout');
		}
	}

	return (
		<Container className="main-container">
			<PageHeader
				ghost={false}
				onBack={() => window.history.back()}
				title="Cart"
				extra={[
					cart.id ? <Title level={5}>{displayCADMoney(parseInt(cart.subTotal) + parseInt(cart.grubxFee) + parseInt(cart.taxAmount))}</Title> : ''
				]}
			>
			</PageHeader>
			<MainContainer>
				<ProductsContainer>
					<List
						itemLayout="horizontal"
						dataSource={cart.cartItems || []}
						renderItem={({ product, quantity, id, extras = [] }) => (
							<List.Item>
								<List.Item.Meta
									avatar={<Avatar onError={() => this.src = defaultImg} shape="square" size={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100, xxl: 100 }} src={product.imageUrl || defaultImg} />}
									title={product.name}
									description={
										<div>
											<div className="product-description">{displayCADMoney(product.price)}</div>
											{extras.length > 0 && <div className="product-description">Extras: {extras.map(extra => extra.name).toString().replace(/,/g, ', ')}</div>}
											<Button type="text" icon={<MinusOutlined />} onClick={() => _updateCartItem(id, quantity - 1)} />
											<span>{quantity}</span>
											<Button type="text" icon={<PlusOutlined />} onClick={() => _updateCartItem(id, quantity + 1)} />
										</div>}
								/>
							</List.Item>
						)}
					/>
				</ProductsContainer>
				{cart.id && <DetailOrderSection buttonText="Checkout" onButtonClick={goToCheckout} />}
			</MainContainer>
		</Container>
	);
}

export default Cart;
