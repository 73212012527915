import React from 'react';

function Footer() {
  return (
    <footer className="Footer">
        Powered by GrubX
    </footer>
  );
}

export default Footer;
