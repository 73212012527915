import { request } from "../helpers/request";
const CONTROLLER_ROUTE = '/orders'
export default class OrderService {
    createPaymentIntent(cartId) {
        return request.post(`${CONTROLLER_ROUTE}/pwa`, { cartId });
    }

    confirmOrder(cartId, sessionId, userName, notes) {
        return request.put(`${CONTROLLER_ROUTE}/pwa-place`, {
            cartId,
            sessionId,
            userName,
            notes
        });
    }

    placeCashOrder(cartId, userName, notes) {
        return request.post(`${CONTROLLER_ROUTE}/pwa-place-cash`, {
            cartId,
            userName,
            notes
        });
    }

    getOne(orderNo) {
        return request.get(`${CONTROLLER_ROUTE}/pwa/${orderNo}`);
    }
}