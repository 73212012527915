import { Card, Modal, Avatar, Space, Radio, List, Tabs, Button, Badge, Typography } from 'antd';
import { PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Container } from 'reactstrap'
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { getStoreInfo } from '../services/store.service';
import { useParams } from "react-router-dom";
import { displayCADMoney } from '../helpers/util';
import styled from 'styled-components';
const defaultImg = 'https://prod-grubx.s3.amazonaws.com/dish-default.jpeg';
const defaultLogo = 'https://prod-grubx.s3.amazonaws.com/grubx-logo.png';
const { Title } = Typography;
const { Meta } = Card;
const { TabPane } = Tabs;


const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
		position: absolute;
		justify-content: space-between;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 10px;

		.anticon {
			font-size: 25px;
			color: #FFFFFF;
		}
`;
const ProductsContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
		margin-bottom: 50px;

    .ant-tabs-tab-btn {
      text-transform: capitalize;
    }
`;

const ProductHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

function Store({ history }) {
  const { store, cart, currentLocation, setCurrentLocation, addItemToCart, setStore } = useContext(AppContext);
  const [locationModalIsVisible, setLocationModalIsVisible] = useState(false);
  const [locationId, setLocationId] = useState();
  const { slug } = useParams();
  useEffect(() => {
    const init = async () => {
      try {
        const _store = await getStoreInfo(slug);
        setStore(_store);
        localStorage.setItem('storeId', _store.id)
      } catch (e) {
        localStorage.clear();
        window.location.href = "/404";
      }

    }
    init();
  }, [setStore, slug])

  useEffect(() => {
    if ((store.locations || []).length > 1 && !localStorage.getItem('locationId')) {
      setLocationModalIsVisible(true);
      setLocationId(store.locations[0].id);
    }
  }, [store.locations]);

  const onPickLocation = () => {
    localStorage.setItem('locationId', locationId);
    const location = (store.locations || []).find(l => l.id === locationId);
    setCurrentLocation(location || {});
    setLocationModalIsVisible(false);
  }

  const quickATC = async (e, productId) => {
    e.stopPropagation();
    await addItemToCart(productId, 1, []);
  }

  return (
    <Container className="main-container">
      <Modal
        title="Pick the store location"
        centered
        visible={locationModalIsVisible}
        okText="Pick"
        onOk={onPickLocation}
        onCancel={() => setLocationModalIsVisible(false)}
      >
        <Radio.Group onChange={e => setLocationId(e.target.value)} value={locationId}>
          <Space direction="vertical">
            {(store.locations || []).map(location => <Radio value={location.id}>
              <Title level={5}>{location.name}</Title>
              <div>{location.address}</div>
            </Radio>)}
          </Space>
        </Radio.Group>
      </Modal>
      <Card
        cover={
          <>
            <div className="cover-fade" />
            <HeaderContainer>
              {cart.id && <Badge size="small" count={(cart.cartItems || []).length}>
                <Button type="text" icon={<ShoppingCartOutlined />} onClick={() => history.push('/cart')} />
              </Badge>}
            </HeaderContainer>
            <img alt="storeLogo" src={store.logoUrl || defaultLogo} />
          </>
        }

      >
        <Meta
          title={
            <ProductHeader>
              <span>{store.name}</span>
              <span>{displayCADMoney(store.orderMinimum)}</span>
            </ProductHeader>
          }
          description={
            <>
              <div>{currentLocation.name}</div>
              <Button style={{padding: '4px 0px'}} type="link" onClick={() => setLocationModalIsVisible(true)} >Change Store Location</Button>
            </>
          }
        />
      </Card>
      <ProductsContainer>
        <Title level={4}>Menu</Title>
        <Tabs color="rgb(60, 180, 76)" defaultActiveKey="all-products">
          {[{ name: 'All products', id: 'all-products' }, ...(store.categories || [])].map((category, index) => (
            <TabPane tab={category.name} key={category.id}>
              <List
                itemLayout="horizontal"
                dataSource={index === 0 ? store.products : store.products.filter(p => p.categories.filter(c => c.id === category.id).length)}
                renderItem={product => (
                  <List.Item onClick={() => history.push(`/product/${product.id}`)}>
                    <List.Item.Meta
                      avatar={<Avatar onError={() => this.src = defaultImg} shape="square" size={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100, xxl: 100 }} src={product.imageUrl || defaultImg} />}
                      title={<p>{product.name}</p>}
                      description={
                        <div>
                          <p className="product-description">{product.description}</p>
                          <div onClick={(e) => quickATC(e, product.id)} ><span style={{ color: 'rgb(60, 180, 76)' }}><PlusOutlined color="rgb(60, 180, 76)" /> Quick add</span></div>
                        </div>}
                    />
                  </List.Item>
                )}
              />
            </TabPane>
          ))}
        </Tabs>
      </ProductsContainer>
    </Container>
  );
}

export default Store;
