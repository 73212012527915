export const saveStoreId = (storeId) => {
    localStorage.setItem('storeId', storeId);
}

export const displayCADMoney = (price) => `${parseFloat(price / 100).toFixed(2)}$`;

export const isLocal = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "192.168.0.6";

const isProd = window.location.hostname === "order.grubx.io";

export const BASE_URL = isProd ? "https://api.grubx.io" : (isLocal ? "http://localhost:3000" : "https://api.dev.grubx.io");

export const OrderStatusText = {
    PENDING: 'PENDING',
    READY_FOR_DRIVER: 'READY_FOR_DRIVER',
    READY_FOR_PICKUP: 'READY_FOR_PICKUP',
    IN_PROGRESS: 'IN_PROGRESS',
    ON_ROUTE_TO_STORE: 'ON_ROUTE_TO_STORE',
    ON_ROUTE_TO_CUSTOMER: 'ON_ROUTE_TO_CUSTOMER',
    COMPLETED: 'COMPLETED',
    PAID: 'PAID',
    REFUNDED: 'REFUNDED',
    PARTIALLY: 'PARTIALLY',
    VOIDED: 'VOIDED'
}
