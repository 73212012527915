import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './AppContext';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Container } from 'reactstrap'
import Store from './screens/Store';
import NotFound from './screens/NotFound';
import Footer from "./components/Footer";
import 'antd/dist/antd.css';
import ProductDetail from './screens/ProductDetail';
import Loader from './components/Loader';
import CartService from './services/cart.service';
import Cart from './screens/Cart';
import { getStoreById } from './services/store.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SplashScreen from './components/SplashScreen';
import Checkout from './screens/Checkout';
import StripeResult from './screens/Result';
import Order from './screens/Order';

const cartService = new CartService();

const Index = () => {
  const [cart, setCart] = useState({});
  const [store, setStore] = useState({});
  const [currentLocation, setCurrentLocation] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (store.id) localStorage.setItem('storeId', store.id);
    const locationId = localStorage.getItem('locationId');
    const location = (store.locations || []).find(l => l.id === locationId);
    setCurrentLocation(location || {});
  }, [store]);

  useEffect(() => {
    const init = async () => {
      try {
        const cartId = localStorage.getItem('cartId');
        const storeId = localStorage.getItem('storeId');
        if (storeId && storeId !== "undefined") {
          const _store = await getStoreById(storeId);
          setStore(_store);
        }
        if (cartId && cartId !== "undefined") {
          const { data } = await cartService.getCart(cartId);
          setCart(data);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        localStorage.clear();
        setStore({});
        setCart({});
        if (window.location.pathname !== "/404") window.location.href = "/404";
      }

    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addItemToCart = async (productId, quantity, extras = []) => {
    try {
      const cartId = localStorage.getItem('cartId');
      const locationId = localStorage.getItem('locationId');
      if (!locationId){
        toast.info(`Pick a location first!`);
        return;
      }
      setLoading(true);
      let response;
      const lineItem = (cart.cartItems || []).find(item => item.productId === productId);
      if (lineItem && !extras.length) {
        const newQty = lineItem.quantity + quantity;
        if (newQty > 0) {
          response = await cartService.updateCartItem(lineItem.id, newQty);
          toast.success(`${lineItem.product.name} updated!`);
        } else {
          response = await cartService.deleteCartItem(lineItem.id);
          toast.info(`${lineItem.product.name} deleted!`);
        }
      } else {
        response = await cartService.addItemToCart(quantity, productId, locationId, cartId, extras);
        const lineItem = (response.data.cartItems || []).find(item => item.productId === productId);
        toast.success(`${lineItem.product.name} added!`);
      }
      setCart(response.data);
      if (!cartId) localStorage.setItem('cartId', response.data.id);
      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);
    }
  }

  const updateCartItem = async (lineItemId, quantity) => {
    try {
      setLoading(true);
      let response;
      if (quantity > 0) {
        response = await cartService.updateCartItem(lineItemId, quantity);
      } else {
        response = await cartService.deleteCartItem(lineItemId);
      }
      setCart(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);
    }
  }

  const removeItemFromCart = async cartItemId => {
    try {
      setLoading(true);
      const { data } = await cartService.deleteCartItem(cartItemId);
      setCart(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const clearCart = async () => {
    try {
      setLoading(true);
      const cartId = localStorage.getItem('cartId');
      for (const item of cart.cartItems) {
        await cartService.deleteCartItem(item.id);
      }
      const { data } = await cartService.getCart(cartId);
      setCart(data);
      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);
    }
  }

  return (
    <React.StrictMode>
      <AppContextProvider value={{ loading, setLoading, cart, setCart, store, setStore, currentLocation, setCurrentLocation, addItemToCart, updateCartItem, removeItemFromCart, clearCart }}>
        <BrowserRouter>
          {loading && <Loader />}
          {!store.id && window.location.pathname !== "/404" && <SplashScreen />}
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Switch>
            <Route exact={true} path="/store/:slug" render={(props) => <Store {...props} />} />
            <Route exact={true} path="/product/:productId" render={(props) => <ProductDetail {...props} />} />
            <Route exact={true} path="/cart" render={(props) => <Cart {...props} />} />
            <Route exact={true} path="/checkout" render={(props) => <Checkout {...props} />} />
            <Route exact={true} path="/result" render={(props) => <StripeResult {...props} />} />
            <Route exact={true} path="/order" render={(props) => <Order {...props} />} />
            <Route exact={true} path="/404" render={(props) => <NotFound {...props} />} />
            <Redirect from="*" to="/404" />
            <Container fluid>
              <Footer />
            </Container>
          </Switch>
        </BrowserRouter>
      </AppContextProvider>
    </React.StrictMode>
  )
}


ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
