import { BASE_URL } from "./util";
const axios = require('axios');

const request = axios.create({
    baseURL: BASE_URL + "/api/v1",
    timeout: 60000
});

export {
    request,
    BASE_URL
};