import { request } from "../helpers/request"
const CONTROLLER_ROUTE = '/cart/pwa';

export default class CartService {
    addItemToCart(quantity, productId, locationId, cartId, extras) {
        return request.post(`${CONTROLLER_ROUTE}/item`, {
            quantity, productId, locationId, cartId, extras
        });
    }

    updateCartItem(cartItemId, quantity) {
        return request.put(`${CONTROLLER_ROUTE}/item/${cartItemId}`, { quantity });
    }
    deleteCartItem(cartItemId) {
        return request.delete(`${CONTROLLER_ROUTE}/item/${cartItemId}`);
    }

    getCart(cartId) {
        return request.get(`${CONTROLLER_ROUTE}/${cartId}`);
    }
}