import { Card, Badge, List, Button, Typography } from 'antd';
import { PlusOutlined, MinusOutlined, ArrowLeftOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Container } from 'reactstrap'
import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { getProductInfo } from '../services/product.service';
import { displayCADMoney } from '../helpers/util';
import { AppContext } from '../AppContext';
import { getStoreById } from '../services/store.service';
import Loader from '../components/Loader';
const defaultImg = 'https://prod-grubx.s3.amazonaws.com/dish-default.jpeg';
const { Title } = Typography;
const { Meta } = Card;

const ExtrasContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
		margin-bottom: 50px;
`;

const ExtraHeader = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
`;

const ProductHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const QuantityContainer = styled.div`
	left: -10px;
	position:relative;
`;

const HeaderContainer = styled.div`
    display: flex;
		position: absolute;
		justify-content: space-between;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 10px;

		.anticon {
			font-size: 25px;
			color: #FFFFFF;
		}
`;

const AddToCartButton = styled.div`
  cursor: pointer;
	text-align: center;
	padding: 10px;
	position: absolute;
  width: 100%;
	color: white;
	font-weight: bold;
	font-size: 20px;
  background-color: rgb(60, 180, 76);
	bottom: 0px;
`;

function ProductDetail({ history }) {
	const { addItemToCart, cart, setStore } = useContext(AppContext);
	const [product, setProduct] = useState({});
	const [price, setPrice] = useState(0);
	const [quantity, setQuantity] = useState(1);
	const [extras, setExtras] = useState([]);
	let { productId } = useParams();


	const fetchProduct = useCallback(async () => {
		const _product = await getProductInfo(productId);
		setProduct(_product);
		setPrice(parseInt(_product.price));
		setExtras(_product.extras.map(extra => ({ ...extra, quantity: 0 })));
	}, [setPrice, setProduct, setExtras, productId])

	const fetchStore = useCallback(async (storeId) => {
		const _store = await getStoreById(storeId);
		setStore(_store);
		localStorage.setItem('storeId', _store.id)
	}, [setStore])

	useEffect(() => {
		if (product.storeId) fetchStore(product.storeId);
	}, [product, fetchStore]);

	useEffect(() => {
		fetchProduct();
	}, [fetchProduct]);

	useEffect(() => {
		try {
			const extrasArray = extras.map(ex => ex.quantity * parseInt(ex.price));
			const extrasValue = extrasArray.length > 0 ? extrasArray.reduce((acc, value) => acc += value) : 0;
			setPrice((parseInt(product.price) * quantity) + extrasValue)
		} catch (e) { }
	}, [extras, quantity, product.price]);

	const addToCart = async () => {
		await addItemToCart(product.id, quantity, extras.filter(ex => ex.quantity > 0));
	}

	const substractExtra = (extraId) => {
		setExtras(prev => {
			const newExtras = prev.map(_ex => {
				if (_ex.id === extraId && _ex.quantity > 0) {
					return {
						..._ex,
						quantity: _ex.quantity - 1
					}
				} else {
					return _ex
				}
			});
			return newExtras;
		});
	}

	const addExtra = (extraId) => {
		setExtras(prev => {
			const newExtras = prev.map(_ex => {
				if (_ex.id === extraId) {
					return {
						..._ex,
						quantity: _ex.quantity + 1
					}
				} else {
					return _ex
				}
			});
			return newExtras;
		});
	}


	return (
		<Container className="main-container">
			{!product.id && <Loader />}
			<Card
				cover={
					<>
						<div className="cover-fade" />
						<HeaderContainer>
							<Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()} />
							{cart.id && <Badge size="small" count={(cart.cartItems || []).length}>
								<Button type="text" icon={<ShoppingCartOutlined />} onClick={() => history.push('/cart')} />
							</Badge>}
						</HeaderContainer>
						<img onError={e => this.src = defaultImg} alt="product logo" src={product.imageUrl || defaultImg} />
					</>
				}

			>
				<Meta
					title={
						<ProductHeader>
							<span>{product.name}</span>
							<span>{displayCADMoney(price)}</span>
						</ProductHeader>
					}
					description={
						<div>
							<div>{product.description}</div>
							<QuantityContainer>
								<Button type="text" icon={<MinusOutlined />} onClick={() => setQuantity(prev => prev > 0 ? prev - 1 : 0)} />
								<span>{quantity}</span>
								<Button type="text" icon={<PlusOutlined />} onClick={() => setQuantity(prev => prev + 1)} />
							</QuantityContainer>
						</div>
					}
				/>
			</Card>
			<AddToCartButton onClick={() => addToCart()}>Add to cart</AddToCartButton>
			{(extras || []).length > 0 && <ExtrasContainer>
				<Title level={4}>Extras</Title>
				<List
					itemLayout="horizontal"
					dataSource={extras || []}
					renderItem={extra => (
						<List.Item>
							<List.Item.Meta
								title={
									<ExtraHeader>
										<span>{extra.name}</span>
										<span>{displayCADMoney(extra.price)}</span>
									</ExtraHeader>
								}
								description={
									<div>
										<Button type="text" icon={<MinusOutlined />} onClick={() => substractExtra(extra.id)} />
										<span>{extra.quantity}</span>
										<Button type="text" icon={<PlusOutlined />} onClick={() => addExtra(extra.id)} />
									</div>
								}
							/>
						</List.Item>
					)}
				/>
			</ExtrasContainer>}

		</Container>
	);
}

export default ProductDetail;
