import { Result, Button } from 'antd';
import { Container } from 'reactstrap'

function NotFound({ history }) {
    return (
        <Container className="main-container">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button onClick={()=> history.goBack()} type="link">Back</Button>}
            />
        </Container>
    );
}

export default NotFound;
