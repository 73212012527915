import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { displayCADMoney } from '../helpers/util';
import styled from 'styled-components';


const AddToCartButton = styled.button`
  cursor: pointer;
  border: none;
	text-align: center;
	padding: 10px;
  width: 100%;
	color: white;
	font-weight: bold;
	font-size: 20px;
  background-color: rgb(60, 180, 76);
	bottom: 0px;
`;

const DetailOrderContainer = styled.div`
	width: 100%;
	background-color: white;
	position: absolute;
	bottom: 0px;
`;

const LineItem = styled.div`
	display: flex;
	padding: 0 10px;
	justify-content: space-between;
	align-items: center;
	margin: 15px 0px;

	span {
		font-weight: bold;
		font-size: 16px;
	}

	.price {
		font-size: 16px;
		font-weight: normal;
	}
`;

function DetailOrderSection({ buttonText, type, onButtonClick = () => {} }) {
    const { cart } = useContext(AppContext);
    return (
        <DetailOrderContainer type={type}>
            <LineItem>
                <span>Sub Total</span>
                <span className="price">{displayCADMoney(cart.subTotal)}</span>
            </LineItem>
            {cart.grubxFee > 0 && <LineItem>
                <span>GrubX Fee</span>
                <span className="price">{displayCADMoney(cart.grubxFee)}</span>
            </LineItem>}
            <LineItem>
                <span>Taxes</span>
                <span className="price">{displayCADMoney(cart.taxAmount)}</span>
            </LineItem>
            <AddToCartButton disabled={(cart.cartItems || []).length === 0} onClick={onButtonClick}>{buttonText}</AddToCartButton>
        </DetailOrderContainer>
    );
}

export default DetailOrderSection;
