import { Form, Input, Radio, Space, PageHeader, Typography } from 'antd';
import { Container } from 'reactstrap'
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { getStoreById } from '../services/store.service';
import { displayCADMoney } from '../helpers/util';
import styled from 'styled-components';
import OrderService from '../services/order.service';
import DetailOrderSection from '../components/DetailOrderContainer';
const { Title } = Typography;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-bottom: 140px;
`;

const ProductsContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 50px;
		overflow-y: scroll;
		flex: 3;
`;

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
const orderService = new OrderService();
function Checkout({history}) {
	const { cart, setStore, setCart, setLoading } = useContext(AppContext);
	const [paymentMethod, setPaymentMethod] = useState('CASH');

	useEffect(() => {
		const fetchStore = async (storeId) => {
			const _store = await getStoreById(storeId);
			setStore(_store);
		}
		if (cart.storeId) fetchStore(cart.storeId);
	}, [cart, setStore])

	const onFinish = async () => {
		setLoading(true);
		const { data } = await orderService.createPaymentIntent(cart.id);
		window.location.href = data;
	}

	const placeCashOrder = async ({ name }) => {
		setLoading(true);
		const { data } = await orderService.placeCashOrder(cart.id, name, '');
		localStorage.removeItem('cartId');
		setCart({});
		setLoading(false);
		history.push(`/order?orderNo=${data.orderNo}`);
	}

	return (
		<Container className="main-container">
			<PageHeader
				ghost={false}
				onBack={() => window.history.back()}
				title="Checkout"
				extra={[
					<Title level={5}>{displayCADMoney(parseInt(cart.subTotal) + parseInt(cart.grubxFee) + parseInt(cart.taxAmount))}</Title>
				]}
			>
			</PageHeader>
			<Form {...layout} name="nest-messages" onFinish={paymentMethod === "CASH" ? placeCashOrder : onFinish} validateMessages={validateMessages}>
				<MainContainer>
					<ProductsContainer>
						<Form.Item name={'name'} label="Name" rules={[{ required: true }]}>
							<Input placeholder="Your name" />
						</Form.Item>
						{/* <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email' }]}>
							<Input placeholder="Your email" />
						</Form.Item> */}
						<Title level={5}>How do you want to pay your order</Title>
						<Radio.Group onChange={e => setPaymentMethod(e.target.value)} value={paymentMethod}>
							<Space direction="vertical">
								<Radio value={'CASH'}>Cash</Radio>
								<Radio value={'STRIPE'}>Card / Apple Pay / Google Pay</Radio>
							</Space>
						</Radio.Group>
					</ProductsContainer>
					{cart.id && <DetailOrderSection buttonText="Order" type="submit" />}
				</MainContainer>
			</Form>
		</Container>
	);
}

export default Checkout;
