import { PageHeader, Steps, Form, Input, Button, Divider } from 'antd';
import { Container } from 'reactstrap'
import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { displayCADMoney } from '../helpers/util';
import { AppContext } from '../AppContext';
import OrderService from '../services/order.service';
import moment from 'moment';
import Loader from '../components/Loader';
moment().format();
const { Step } = Steps;
const STEPS = {
    PENDING: 0,
    PAID: 1,
    IN_PROGRESS: 2,
    READY_FOR_PICKUP: 3,
    COMPLETED: 4,
}
const REFUNDED_STATUS = {
    FULL: 'Your order was cancelled and fully refunded',
    NO_REFUND: 'Your order was cancelled',
    PARTIALLY: 'Your order was cancelled and partially refunded'
}
const StepsContainer = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const orderService = new OrderService()
function Order({ location, history }) {
    const { setLoading, loading } = useContext(AppContext);
    const [order, setOrder] = useState({});
    const orderNo = new URLSearchParams(location.search).get("orderNo")


    const trackOrder = useCallback(async (_orderNo) => {
        try {
            setLoading(true);
            const { data } = await orderService.getOne(_orderNo);
            window.history.replaceState(null, "Order tracking", `/order?orderNo=${_orderNo}`)
            setOrder(data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, [setOrder, setLoading]);

    useEffect(() => {
        if (orderNo) trackOrder(orderNo);
    }, [orderNo, trackOrder]);

    const onFinish = async data => {
        try {
            setLoading(true);
            await trackOrder(data.orderNo);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    return (
        <Container className="main-container">
            {loading && <Loader />}
            <PageHeader
                style={{boxShadow: '0px 0px 5px 2px lightgray' }}
                ghost={false}
                onBack={() => history.replace(`/store/${order.store.slug}`)}
                title={order.orderNo ? `Order #${order.orderNo}` : 'Track Order'}
                extra={[
                    orderNo && <Button onClick={() => trackOrder(orderNo)} type="link">Refresh</Button>
                ]}
            >
            </PageHeader>
            <StepsContainer>
                {!order.id && <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                    <Form.Item name={'orderNo'} label="Order Number" rules={[{ required: true }]}>
                        <Input step={1} type="number" placeholder="The number you got before you placed the order" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">Track</Button>
                    </Form.Item>
                </Form>
                }
                {
                    order.id && <>
                        <Divider />
                        <p><b>Created:</b> {moment(order.dateCreated).fromNow()}</p>
                        <p><b>Order ready:</b> {moment(order.dateEstimatedDelivery).fromNow()}</p>
                        <p><b>Total:</b> {displayCADMoney(order.total)}</p>
                        {order.stripeTransactionId === "CASH" && <p><b>Paid with:</b> {order.stripeTransactionId}</p>}
                        <Divider />

                        {
                            order.status === "VOIDED" ? (
                                <Steps direction="vertical" current={2}>
                                    <Step value title="Waiting for payment" description="Your order is not paid yet, please complete the payment" />
                                    <Step title="Paid and waiting for cooking" description="Your order is ready but the restaurant is not preparing it yet" />
                                    <Step title="Voided" description={REFUNDED_STATUS[order.refundStatus] || 'Your order was cancelled'} />
                                </Steps>
                            ) : (
                                <Steps direction="vertical" current={STEPS[order.status]}>
                                    <Step value title="Waiting for payment" description="Your order is not paid yet, please complete the payment" />
                                    <Step title="Paid and waiting for cooking" description="Your order is ready but the restaurant is not preparing it yet" />
                                    <Step title="Preparing" description="The restaurant is preparing the order" />
                                    <Step title="Order ready" description="Your order is ready, enjoy it!" />
                                    <Step title="Order delivered" description="Your order was completed" />
                                </Steps>
                            )
                        }
                    </>
                }
            </StepsContainer>
        </Container>
    );
}

export default Order;
