import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import logo from '../assets/img/logo.png';

const LoaderContainer = styled.div`
    background: rgba(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const Logo = styled.img`
    height: 40px;
`;

const SplashScreen = () => (
    <LoaderContainer>
        <div style={{flexDirection: 'column', display: 'flex'}}>
            <LoadingOutlined style={{fontSize: 30}} />
            <Logo src={logo} />
        </div>
    </LoaderContainer>
)

export default SplashScreen;