import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	Container,
	Col,
	Row,
} from "reactstrap";
import { Result, Button } from 'antd';
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import OrderService from "../services/order.service";
import Loader from "../components/Loader";
const orderService = new OrderService();
const StripeResult = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const session_id = urlParams.get('session_id');
	const { store, cart, setCart } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [order, setOrder] = useState({});
	const history = useHistory();


	useEffect(() => {
		const init = async () => {
			if (store.id && cart.id && session_id) {
				try {
					setLoading(true);
					const { data: order } = await orderService.confirmOrder(cart.id, session_id);
					setOrder(order);
					localStorage.removeItem('cartId');
					setCart({});
					setLoading(false);
				} catch (e) {
					setLoading(false);
				}

			}
		}
		init();
	}, [store, cart, session_id, setCart])

	if (loading) return <Loader text="Checking order" />

	return (
		<>
			<Container className="mt--7" fluid>
				<Row>
					<Col>
						<Card className="bg-secondary shadow border-0">
							<CardBody className="px-lg-5 py-lg-5">
								{
									store.id && order.id ? (
										<Result
											status="success"
											title={`Order #${order.orderNo} paid!`}
											subTitle="Your order will be ready in a few minutes"
											extra={[
												<Button onClick={() => history.push(`/order?orderNo=${order.orderNo}`)} type="link">
													Track Order
												</Button>,
											]}
										/>
									) : store.id ? (
										<Result
											status="error"
											title={`Your payment was declined`}
											subTitle="Please check your payment method and try again"
											extra={[
												<Button onClick={() => history.replace('/store/' + store.slug)} type="link">
													Go to home
												</Button>,
											]}
										/>
									) : (
										<Result
											status="info"
											title={`Checking Purchase Result`}
											subTitle="Please wait while we validate if the payment is correctly processed"
											extra={[
												<Button onClick={() => history.push('/order')} color="primary">
													Track Order
												</Button>,
											]}
										/>
									)
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default StripeResult;
